import { useEffect } from "react";
import { useLocation } from "react-router-dom";

const ScrollToTop = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    // Scroll to the top with smooth transition
    window.scrollTo({
      top: 0,
      behavior: "smooth"
    });
  }, [pathname]); // This will trigger on route change

  return null;  // No need to render anything
};

export default ScrollToTop;
