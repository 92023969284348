import React, { useState } from "react";
import "./Home.css";
import "./Product.css";
import { Link } from "react-router-dom";
import NavBar from "./Navbar";
const Home = () => {
  const [activeIndex, setActiveIndex] = useState(null); // Store the index of the active question

  const toggleFAQ = (index) => {
    // Toggle the active FAQ
    setActiveIndex((prevIndex) => (prevIndex === index ? null : index));
  };

  const [isOpen, setIsOpen] = useState(false);
  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const scrollToSection = () => {
    const element = document.getElementById("ok");
    if (element) {
      element.scrollIntoView({ behavior: "smooth" }); // Smooth scroll effect
    }
  };
  return (
    <div className="Home-main">
      <NavBar></NavBar>
      <div className="main-2">
        <div className="main-23">
          <h2 className="hide">
            One - Stop Solution for all Your
            <br /> Car Accessory Needs
          </h2>
          <div className="para-div">
            <p>
              At Cradder, we are your one-stop solution for all your car
              accessory needs. Whether you're looking to enhance your vehicle's
              performance, safety, or style, we offer a wide range of
              high-quality products to meet every requirement.
            </p>
          </div>
          <div className="button-div">
            <button className="button-main">
              {" "}
              <Link to="/contact" className="okp">
                Schedule a Call
              </Link>
            </button>
            <a href="https://www.instagram.com/cradderdelhi?igsh=ZzZ0a2JpMmZlNXpu&utm_source=qr">
              Follow instagram
            </a>
          </div>
        </div>
        <div className="main-22">
          <img src="2.png" className="img-23" />
          <img src="3.png" className="img-24" />
        </div>
      </div>
      <div className="scroll-div" onClick={scrollToSection}>
        <img src="try2.png" className="try"></img>
        <img src="scroll.png" className="try2"></img>
        <img src="try.png" className="try"></img>
      </div>
      <div className="features-div">
        <div className="cars-div">
          <img src="cars.png" />
        </div>
        <div className="cards-div">
          <h2>Our features</h2>
          <p>
            At <span className="cards-orange">Cradder</span>, our aim has always
            been to deliver, innovate, and offer the best solutions to elevate
            the automobile experience. 
          </p>
          <div className="cards-main">
            <div className="cards-1">
              <img src="card1.png"></img>
              <img src="card2.png"></img>
            </div>
            <div className="cards-2">
              <img src="card3.png"></img>
              <img src="card4.png"></img>
            </div>
          </div>
        </div>
      </div>
      <div className="main-3" id="ok">
        <div className="product-heading">
          <h2>Discover our Top Selling Products</h2>
          <p>
            Explore our Top Selling products and see the best reviewed products
            that we've brought to life.
          </p>
        </div>
        <div className="product-button">
          <Link to="/product">
            <img src="button.png" />
          </Link>
        </div>
      </div>

      <div class="card-container">
        <Link to="/product/35">
          <div class="card">
            <img src="6.png" alt="Moving Guide Line Camera" />
            <div class="card-content">
              <h3>LED Headlight 240W/Pair</h3>
              <p>
                Focused beam technology and energy conservation with super long
                lifespan. More visibilty during night, premium quality and wet
                built.
              </p>
              <span class="category">Lights</span>
            </div>
          </div>
        </Link>
        <Link to="/product/31">
          <div class="card">
            <img src="sort.jpg" alt="Cradder LED Headlight" />
            <div class="card-content">
              <h3>Full HD Touch Screen Android</h3>
              <p>
                Dynamic large 9inch and 10inch capacitive HD display enjoy
                smooth and simple control for every journey, with a responsible
                diagonal 9inch and 10inch
              </p>
              <span class="category">Screen</span>
            </div>
          </div>
        </Link>
        <Link to="/product/37">
          <div class="card">
            <img src="4.png" alt="Full HD Touch Screen Android" />
            <div class="card-content">
              <h3>Full HD Wide Camera System </h3>
              <p>
                Full HD camera with high definition. Wide view angle and video
                rear view system.Water proof and also have a night vision with
                low consumption.
              </p>
              <span class="category">Camera</span>
            </div>
          </div>
        </Link>
      </div>
      <div className="test-heading">
        <h2>Real Stories From Satisfied Customer</h2>
        <p>See how our products are creating making an impact.</p>
      </div>
      <div className="testi">
        <div className="testi1">
          <img src="test1.png"></img>
        </div>
        <div className="testi2">
          <img src="test2.png"></img>
        </div>
      </div>
      <div className="test-heading">
        <h2>Meet Our Team</h2>
        <p>
          Get to know the faces behind the scenes and learn about the values
          that drive us.{" "}
        </p>
      </div>
      <div class="profile-card-container">
        <Link to="/about">
          <div class="profile-card">
            <img src="pic1.jpg" alt="Mr. Deepak Ahuja" />
            <div class="profile-card-content">
              <h3>Mr. Deepak Ahuja</h3>
              <h4>Owner</h4>
              <p>
                Mr. Deepak Ahuja, the visionary owner of Cradder, founded the
                company with a passion for transforming the car accessory
                industry.
              </p>
            </div>
          </div>
        </Link>
        <Link to="/about">
          <div class="profile-card">
            <img src="pic4.png" alt="Production Head" />
            <div class="profile-card-content">
              <h3>Mr. Vipul Vadhaan</h3>
              <h4>Mentor</h4>
              <p>
                Mr. Vipul Vadhaan With years of experience in the automotive
                industry, he has been instrumental in guiding the company's
                vision and growth
              </p>
            </div>
          </div>
        </Link>
        <Link to="/about">
          <div class="profile-card">
            <img src="pic4.png" alt="Sales Head" />
            <div class="profile-card-content">
              <h3>Mr. Moksh Ahuja</h3>
              <h4>Manager</h4>
              <p>
                Mr. Moksh Ahuja, the Manager at Cradder, plays a pivotal role in
                overseeing daily operations and ensuring seamless execution of
                the company’s vision.
              </p>
            </div>
          </div>
        </Link>
      </div>
      <div className="carBrands">
        <p className="carBrands-head">Over 20 Car Brands Available</p>
        <p className="carBrands-subhead">
          We have accessories available for over 20 car brands
        </p>
      </div>
      <div className="carBrands-logo1">
        <img src="/images/logo1.png"></img>
        <img src="/images/logo2.png"></img>
        <img src="/images/logo3.png"></img>
        <img src="/images/logo4.png"></img>
        <img src="/images/logo5.png"></img>
      </div>
      <div className="carBrands-logo2">
        <img src="/images/logo6.png"></img>
        <img src="/images/logo7.png"></img>
        <img src="/images/logo8.png"></img>
        <img src="/images/logo9.png"></img>
      </div>
      <div className="carBrands-logo3">
        <img src="/images/logo10.png"></img>
        <img src="/images/logo11.png"></img>
        <img src="/images/logo12.png"></img>
      </div>
      <div className="carBrand-but">
        <Link to="/product">
          <button>View All Products</button>
        </Link>
      </div>
      <div className="test-heading">
        <h2>Got Questions? We’ve Got Answers!</h2>
      </div>
      
      <div className="qa">
        {/* FAQ item 1 */}
        <div className="qa1">
          {activeIndex === 0 ? (
            <div className="qa-2">
              <div className="answer-div">
                <h4>How do I choose the right Accessory for my Car?</h4>
                <img src="close.png" onClick={() => toggleFAQ(0)} />
              </div>
              <div className="qa-3">
                <p>
                  We provide a wide range of high-quality car accessories,
                  including cameras, LED headlights, Android players, tyre
                  inflators, audio systems, and more. Whether you’re looking for
                  enhanced safety features or upgrading your car's look and
                  feel, our products cater to all your automotive needs.
                </p>
              </div>
            </div>
          ) : (
            <div className="qa-1">
              <h4>How do I choose the right Accessory for my Car?</h4>
              <img src="button.png" onClick={() => toggleFAQ(0)} />
            </div>
          )}
        </div>

        {/* FAQ item 2 */}
        <div className="qa1">
          {activeIndex === 1 ? (
            <div className="qa-2">
              <div className="answer-div">
                <h4>Are your products compatible with all car models?</h4>
                <img src="close.png" onClick={() => toggleFAQ(1)} />
              </div>
              <div className="qa-3">
                <p>
                  Yes, most of our products, like cameras and headlights, are
                  designed to fit a variety of car models. For specific items
                  like OEM cameras or custom fittings, we recommend checking the
                  product details or contacting us to ensure compatibility with
                  your vehicle.
                </p>
              </div>
            </div>
          ) : (
            <div className="qa-1">
              <h4>Are your products compatible with all car models?</h4>
              <img src="button.png" onClick={() => toggleFAQ(1)} />
            </div>
          )}
        </div>

        {/* FAQ item 3 */}
        <div className="qa1">
          {activeIndex === 2 ? (
            <div className="qa-2">
              <div className="answer-div">
                <h4>How can I contact customer support?</h4>
                <img src="close.png" onClick={() => toggleFAQ(2)} />
              </div>
              <div className="qa-3">
                <p>
                  You can reach our customer support team via phone, email, or
                  live chat. Visit the “Contact Us” page on our website for
                  detailed contact information and hours of operation.
                </p>
              </div>
            </div>
          ) : (
            <div className="qa-1">
              <h4>How can I contact customer support?</h4>
              <img src="button.png" onClick={() => toggleFAQ(2)} />
            </div>
          )}
        </div>

        {/* FAQ item 4 */}
        <div className="qa1">
          {activeIndex === 3 ? (
            <div className="qa-2">
              <div className="answer-div">
                <h4>Do your products come with a warranty?</h4>
                <img src="close.png" onClick={() => toggleFAQ(3)} />
              </div>
              <div className="qa-3">
                <p>
                  Yes, most of our products come with a warranty covering defects in
                  materials and workmanship. Please check each product's details for
                  specific warranty information.
                </p>
              </div>
            </div>
          ) : (
            <div className="qa-1">
              <h4>Do your products come with a warranty?</h4>
              <img src="button.png" onClick={() => toggleFAQ(3)} />
            </div>
          )}
        </div>
      </div>
      <footer>
        <p className="footer-heading">Subscribe to Our Newsletter</p>
        <p className="footer-desc">
          Subscribe to our newsletter today to get all the latest updates!
        </p>
        <Link to="/contact">
          <button className="footer-but">
            <img src="/images/RocketLaunch.png"></img>
            Get Started
          </button>
        </Link>
        <div className="footer-img">
          <img src="/images/footer-bg2.png"></img>
        </div>
      </footer>
    </div>
  );
};

export default Home;
