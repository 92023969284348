import React, { useState } from "react";
import "./Navbar.css";
import { Link } from 'react-router-dom';
const NavBar = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className="navMain">
      <div className="navImg">
      <a href="/">
        <img src="logo3.png" alt="Logo" />
      </a>
      </div>
      <div className={`navtext ${isOpen ? "showMenu" : ""}`}>
      <Link to="/">Home</Link>
<Link to="/product">Products</Link>
<Link to="/about">About</Link>
<Link to="/contact">Contact</Link>
      </div>
      {/* <div className="navBut">
        <button className="signInBut">Sign In</button>
      </div> */}
      <div className="hamburger" onClick={toggleMenu}>
        <div className="line"></div>
        <div className="line"></div>
        <div className="line"></div>
      </div>
    </div>
  );
};

export default NavBar;
