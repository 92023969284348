import React from "react";
import "./Contact.css";
import NavBar from "./Navbar";
import { Link } from "react-router-dom";
import { useState } from "react";

const Contact = () => {
  function openWhatsapp() {
    window.open(
      "https://wa.me/7042800432?text=Hello%20Cradder,%20I%20am%20interested%20in%20your%20car%20accessories.%20Could%20you%20please%20provide%20more%20details?",
      "_blank"
    );
  }
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    message: "",
  });

  const [errors, setErrors] = useState({});

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const validateForm = () => {
    const newErrors = {};

    // First Name Validation
    if (!/^[a-zA-Z]{2,}$/.test(formData.firstName)) {
      newErrors.firstName = "First name must be at least 2 letters.";
    }

    // Last Name Validation
    if (!/^[a-zA-Z]{2,}$/.test(formData.lastName)) {
      newErrors.lastName = "Last name must be at least 2 letters.";
    }

    // Email Validation
    if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(formData.email)) {
      newErrors.email = "Email is not valid.";
    }

    // Phone Number Validation
    if (!/^\d{10}$/.test(formData.phone)) {
      newErrors.phone = "Phone number must be 10 digits.";
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (validateForm()) {
      // Handle form submission logic here
      console.log(formData);
      // Reset form after submission
      setFormData({
        firstName: "",
        lastName: "",
        email: "",
        phone: "",
        message: "",
      });
      setErrors({});
    }
  };

  return (
    <div className="contact-div">
      <NavBar></NavBar>
      <div className="product-main">
        <div className="product-main1">
          <p className="sort">Contact Us </p>
          {/* <p className="sort2">Up to-40% off</p> */}
          <p className="f4">
            We're here to help! Whether you have questions about our products,
            need assistance with an order, or want to learn more about how we
            can enhance your driving experience, feel free to reach out. Our
            dedicated support team is ready to provide the information and
            solutions you need. Get in touch with us today and let us help you
            upgrade your car with the best accessories.
          </p>
          {/* <button>See Offers ↗</button> */}
        </div>
        <div className="product-main2">
          <div className="product-img">
            <img src="/images/img1.png"></img>
          </div>
          <div className="product-img">
            <img src="/images/img2.png"></img>
          </div>
          <div className="product-img1">
            <img src="/images/img3.png"></img>
          </div>
          <div className="product-img1">
            <img src="/images/img4.png"></img>
          </div>
        </div>
      </div>
      <div className="contact-main">
        <div className="contacts">
          <div>
            <p className="contact-head">Let's talk with us</p>
            <p className="contact-para">
              Questions, comments, or suggestions? Simply fill in the form and
              we’ll be in touch shortly.
            </p>
          </div>
          <div className="location">
            <p><a href="https://www.google.com/maps/place/Better+Deals/@28.6629836,77.2292704,17z/data=!3m1!4b1!4m6!3m5!1s0x390cfd058ba11e8b:0xfc74fb79a10be608!8m2!3d28.6629836!4d77.2292704!16s%2Fg%2F11b7y9jzz5?entry=ttu&g_ep=EgoyMDI0MDkyMy4wIKXMDSoASAFQAw%3D%3D">
              <img src="location.png" /> &nbsp;&nbsp;3066/8, GALI KALYAN SINGH, MORI GATE, KASHMERE GATE, NEW DELHI-110006
              </a>
            </p>
          </div>
          <div className="mail">
          <p>
              <img src="phones.png" />{" "}
              <a href="tel:9810166931"> &nbsp;&nbsp;9810166931</a>
              &nbsp;&nbsp;
              <a href="tel:7042800432">7042800432</a>
            </p>

          </div>
          <div className="phones">
            <p>
            <a href="mailto:cradderdelhi@gmail.com">
                <img src="mail.png" /> &nbsp;&nbsp;cradderdelhi@gmail.com
              </a>
            </p>
          </div>
          <div className="social">
            <a
              href="https://www.instagram.com/cradderdelhi?igsh=ZzZ0a2JpMmZlNXpu&utm_source=qr"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src="insta.png" alt="Instagram" />
            </a>
            <a
              onClick={openWhatsapp}
            >
              <img src="whatsapp.png" alt="WhatsApp" />
            </a>
            {/* <a
              href="https://www.facebook.com/yourFacebookProfile"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src="facebook.png" alt="Facebook" />
            </a> */}
            <a
              href="https://maps.app.goo.gl/7WwRAuhYTDoKnUzV9?g_st=iw"
            >
              <img src="maps.png" alt="YouTube" />
            </a>
          </div>
        </div>
        <div className="contact-form">
          <div className="form-container">
            <form onSubmit={handleSubmit}>
              <div className="form-group">
                <input
                  type="text"
                  name="firstName"
                  value={formData.firstName}
                  onChange={handleChange}
                  placeholder="First Name*"
                  required
                />
                {errors.firstName && (
                  <span className="error">{errors.firstName}</span>
                )}
              </div>

              <div className="form-group">
                <input
                  type="text"
                  name="lastName"
                  value={formData.lastName}
                  onChange={handleChange}
                  placeholder="Last Name*"
                  required
                />
                {errors.lastName && (
                  <span className="error">{errors.lastName}</span>
                )}
              </div>

              <div className="form-group">
                <input
                  type="email"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                  placeholder="Email*"
                  required
                />
                {errors.email && <span className="error">{errors.email}</span>}
              </div>

              <div className="form-group">
                <input
                  type="tel"
                  name="phone"
                  value={formData.phone}
                  onChange={handleChange}
                  placeholder="Phone Number*"
                  required
                />
                {errors.phone && <span className="error">{errors.phone}</span>}
              </div>

              <div className="form-group">
                <textarea
                  name="message"
                  value={formData.message}
                  onChange={handleChange}
                  rows="4"
                  placeholder="Your message"
                />
              </div>

              <button type="submit">Send Message</button>
            </form>
          </div>
        </div>
      </div>
      <div className="carBrands">
        <p className="carBrands-head">Over 20 Car Brands Available</p>
        <p className="carBrands-subhead">
          We have accessories available for over 20 car brands
        </p>
      </div>
      <div className="carBrands-logo1">
        <img src="/images/logo1.png"></img>
        <img src="/images/logo2.png"></img>
        <img src="/images/logo3.png"></img>
        <img src="/images/logo4.png"></img>
        <img src="/images/logo5.png"></img>
      </div>
      <div className="carBrands-logo2">
        <img src="/images/logo6.png"></img>
        <img src="/images/logo7.png"></img>
        <img src="/images/logo8.png"></img>
        <img src="/images/logo9.png"></img>
      </div>
      <div className="carBrands-logo3">
        <img src="/images/logo10.png"></img>
        <img src="/images/logo11.png"></img>
        <img src="/images/logo12.png"></img>
      </div>
      <div className="carBrand-but">
        <Link to="/product">
          <button>View All Products</button>
        </Link>
      </div>
      <footer>
        <p className="footer-heading">Subscribe to Our Newsletter</p>
        <p className="footer-desc">
          Subscribe to our newsletter today to get all the latest updates!
        </p>
        <Link to="/contact">
          <button className="footer-but">
            <img src="/images/RocketLaunch.png"></img>
            Get Started
          </button>
        </Link>
        <div className="footer-img">
          <img src="/images/footer-bg2.png"></img>
        </div>
      </footer>
    </div>
  );
};

export default Contact;
