import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { datas } from './data';
import './Product.css';
import { Link } from 'react-router-dom';
import "./Navbar.css";

const ProductDetail = () => {
    const [isOpen, setIsOpen] = useState(false);

    const toggleMenu = () => {
        setIsOpen(!isOpen);
    };

    useEffect(()=>{
        window.scrollTo(0,0);
    },[])
    

    const { id } = useParams();
    const product = datas.find((product) => product.id === parseInt(id));

    if (!product) {
        return <div>Product not found</div>;
    }

    

    return (
        <div className="product-detail">
            <div className="navMain">
                <div className="navImg">
                <a href="/">
                    <img src="/logo3.png" alt="Logo" />
                </a>
                </div>
                <div className={`navtext ${isOpen ? "showMenu" : ""}`}>
                <Link to="/">Home</Link>
                        <Link to="/product">Products</Link>
                        <Link to="/about">About</Link>
                        <Link to="/contact">Contact</Link>
                </div>
                <div className="hamburger" onClick={toggleMenu}>
                    <div className="line"></div>
                    <div className="line"></div>
                    <div className="line"></div>
                </div>
            </div>
            <div className='pro-det-container'>
                <div className='pro-det-img'>
                    <img src={product.image}></img>
                </div>
                <div className='pro-det-desc'>
                    <p className='pro-det-head'>{product.name}</p>
                    <p className='pro-det-subhead'>Description</p>
                    <p className='pro-det-subhead'>{product.description}</p>
                    <Link to="/contact"><button className='pro-det-btn'>Enquire Now</button></Link>
                </div>

            </div>
            <div className='pro-det-top3'>
                <p className='pro-det-top3-1'>Our Features</p>
                <p className='pro-det-top3-2'>At <span>Cradder</span>, our aim has always been to deliver, innovate and offer the best solutions to elevate the automobile experience.</p>
            </div>
            <div className='features-cont'>
                <div className='features-cont1'>
                    <img src='/images/features2.png'></img>
                </div>
                <div className='features-cont2'>
                    <div className='flex'>
                        <div className='fe3'>
                            <img src='/images/Icon.png'></img>
                            <p>Fast Shipping</p>
                        </div>
                        <div className='fe3'>
                            <img src='/images/Icon1.png'></img>
                            <p>Easy to Shop</p>
                        </div>
                    </div>
                    <div className='flex'>
                        <div className='fe3'>
                            <img src='/images/Icon2.png'></img>
                            <p>Free Returns</p>
                        </div>
                        <div className='fe3'>
                            <img src='/images/Icon3.png'></img>
                            <p>24*7 Support</p>
                        </div>
                    </div>
                </div>
            </div>
            <div className='carBrands'>
                <p className='carBrands-head'>Over 20 Car Brands Available</p>
                <p className='carBrands-subhead'>We have accessories available for over 20 car brands</p>
            </div>
            <div className='carBrands-logo1'>
                <img src='/images/logo1.png'></img>
                <img src='/images/logo2.png'></img>
                <img src='/images/logo3.png'></img>
                <img src='/images/logo4.png'></img>
                <img src='/images/logo5.png'></img>
            </div>
            <div className='carBrands-logo2'>
                <img src='/images/logo6.png'></img>
                <img src='/images/logo7.png'></img>
                <img src='/images/logo8.png'></img>
                <img src='/images/logo9.png'></img>
            </div>
            <div className='carBrands-logo3'>
                <img src='/images/logo10.png'></img>
                <img src='/images/logo11.png'></img>
                <img src='/images/logo12.png'></img>
            </div>
            <div className='carBrand-but'>
                <button>View All Products</button>
            </div>
            <footer>
                <p className='footer-heading'>Subscribe to Our Newsletter</p>
                <p className='footer-desc'>Subscribe to our newsletter today to get all the latest updates!</p>
                <button className='footer-but'>
                    <img src='/images/RocketLaunch.png'></img>
                    Get Started
                </button>
                <div className='footer-img'>
                    <img src='/images/footer-bg2.png'></img>
                </div>
            </footer>
        </div>
    );
};

export default ProductDetail;
