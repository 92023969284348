import React from "react";
import "./About.css";
import NavBar from "./Navbar";
import { Link } from "react-router-dom";
const About = () => {
  const scrollToSection = () => {
    const element = document.getElementById("meet");
    if (element) {
      element.scrollIntoView({ behavior: "smooth" }); // Smooth scroll effect
    }
  };
  return (
    <div className="aboutMain">
      <div className="aboutText">
        <div className="aboutBG">
          <img src="aboutBG.png" />
        </div>
        <NavBar></NavBar>
        <p className="aboutTitle">
          ABOUT <span>US</span>
        </p>
        <p className="aboutDesc">
          Cradder is a leading brand in the automotive accessory industry,
          committed to delivering high-quality products that enhance both the
          performance and aesthetic appeal of vehicles. Founded with a passion
          for innovation, we offer a wide range of car accessories, including
          cameras, lighting systems, infotainment solutions, and more.
        </p>
        <div className="aboutScroll" onClick={scrollToSection}>
          <img src="scroll2.png" />
          <p>Scroll Down</p>
          <img src="scrollArrow.png" />
        </div>
      </div>
      <div className="aboutMid">
        <div className="aboutMidBG">
          <img src="aboutMidBG.png" />
        </div>
        <div className="aboutLogo">
          <img src="aboutLine.png" className="aboutLine" />
          <img src="logo.png" />
          <img src="aboutLine.png" className="aboutLine" />
        </div>
        <p>
          Our mission is to provide reliable, cutting-edge solutions that meet
          the evolving needs of our customers, ensuring their vehicles are
          equipped for any journey.
        </p>
      </div>
      <div className="ourTeam">
        <div className="ourTeamBG">
          <img src="ourTeamBG.png" />
        </div>
        <div className="ourTeamSub">
          <div className="ourTeamTitle" id="meet">
            <p>
              OUR <span>TEAM </span>
            </p>
          </div>
          <div className="ourTeam1">
            <div className="ourTeam1Text">
              <div className="ourTeam1Img2">
                <img src="pic1.jpg" />
              </div>
              <p className="textName">MR. DEEPAK AHUJA</p>
              <p className="textPosition">Owner, Cradder</p>
              <p className="textDesc">
                Mr. Deepak Ahuja, the visionary owner of Cradder, founded the
                company with a passion for transforming the car accessory
                industry. Since its inception, he has been the driving force
                behind the brand’s growth and success. Deepak's entrepreneurial
                spirit, combined with his dedication to innovation and customer
                satisfaction, has made Cradder a trusted name in automotive
                solutions.
              </p>
            </div>
            <div className="ourTeam1Img1">
              <img src="pic1.jpg" />
            </div>
          </div>
          <div className="ourTeam2">
            <div className="ourTeam2Text">
              <div className="ourTeam1Img3">
                <img src="8.jpg" />
              </div>
              <div>
                <p className="textName">Mr. Vipul Vadhaan</p>
                <p className="textPosition">Mentor, Cradder</p>
                <p className="textDesc">
                  Mr. Vipul Vadhaan, the mentor at Cradder, brings a wealth of
                  knowledge and expertise to the team. With years of experience
                  in the automotive industry, he has been instrumental in
                  guiding the company's vision and growth. 
                </p>
              </div>
            </div>
          </div>
          <div className="ourTeam1">
            <div className="ourTeam1Text">
              <div className="ourTeam1Img2">
                <img src="7.jpg" />
              </div>
              <p className="textName">Mr. Moksh Ahuja</p>
              <p className="textPosition">Manager, Cradder</p>
              <p className="textDesc">
                Mr. Moksh Ahuja, the Manager at Cradder, plays a pivotal role in
                overseeing daily operations and ensuring seamless execution of
                the company’s vision. With a keen eye for detail and a passion
                for the automotive sector, Moksh is dedicated to driving growth
                and enhancing customer satisfaction.
              </p>
            </div>
            <div className="ourTeam1Img1">
              <img src="7.jpg" />
            </div>
          </div>
        </div>
      </div>
      <div className="footer-bg">
      <div className="carBrands">
        <p className="carBrands-head">Over 20 Car Brands Available</p>
        <p className="carBrands-subhead">
          We have accessories available for over 20 car brands
        </p>
      </div>
      <div className="carBrands-logo1">
        <img src="/images/logo1.png"></img>
        <img src="/images/logo2.png"></img>
        <img src="/images/logo3.png"></img>
        <img src="/images/logo4.png"></img>
        <img src="/images/logo5.png"></img>
      </div>
      <div className="carBrands-logo2">
        <img src="/images/logo6.png"></img>
        <img src="/images/logo7.png"></img>
        <img src="/images/logo8.png"></img>
        <img src="/images/logo9.png"></img>
      </div>
      <div className="carBrands-logo3">
        <img src="/images/logo10.png"></img>
        <img src="/images/logo11.png"></img>
        <img src="/images/logo12.png"></img>
      </div>
      <div className="carBrand-but">
      <Link to="/product">
          <button>View All Products</button>
        </Link>
      </div>
      <footer>
        <p className="footer-heading">Subscribe to Our Newsletter</p>
        <p className="footer-desc">
          Subscribe to our newsletter today to get all the latest updates!
        </p>
        <Link to="/contact">
        <button className="footer-but">
          <img src="/images/RocketLaunch.png"></img>
          Get Started
        </button>
        </Link>
        <div className="footer-img">
          <img src="/images/footer-bg2.png"></img>
        </div>
      </footer>
      </div>
    </div>
  );
};

export default About;
