export const datas = [
    {
      id: 1,
      name: "Car Tweeter",
      description: "It is having a 320W as max power and it is 20mm dome tweeter.",
      image: "/images/pro1.png",
    },
    {
      id: 2,
      name: "Car Mat",
      description: "It is corrosion proof, water proof, dust proof and heat resistant.",
      image: "/images/pro2.png",
    },
    {
      id: 3,
      name: "Car Fan",
      description: "It is a premium quality product which is best suitable for car, jeep, van and tempo.",
      image: "/images/pro3.png",
    },
    {
        id: 4,
        name: "Car Key Cover",
        description: "Made by 100% genuine leather.",
        image: "/images/pro4.png",
      },
      {
        id: 5,
        name: "Metal Wiper Blade",
        description: "The avaiable sizes are 12inch, 13inch, 14inch to 26inch.",
        image: "/images/pro5.png",
      },
      {
        id: 6,
        name: "Crad Afforest",
        description: "It is water and dust proof with UV protection and heat resistance.",
        image: "/images/pro6.png",
      },
      {
        id: 7,
        name: "Crad Grey",
        description: "It is water and dust proof with UV protection and heat resistance.",
        image: "/images/pro7.png",
      },
      {
        id: 8,
        name: "Car USB Charger",
        description: "It contains 2 SUB slots and have a 3.1 AMP with fast charging feature.",
        image: "/images/pro8.png",
      },
      {
        id: 9,
        name: "Car Mat",
        description: "It is corrosion proof, water proof, dust proof and heat resistant with 12 MM and 18 MM as available sizes.",
        image: "/images/pro9.png",
      },
      {
        id: 10,
        name: "Stitching Steering Cover",
        description: "Pack of 6 pieces.",
        image: "/images/pro10.png",
      },
      {
        id: 11,
        name: "DVR",
        description: "It has adas driving assistance function with live streaming on andriod and front recording. SD-Card upto 32-128GB with 170deg wide angle view.",
        image: "/images/pro11.png",
      },
      {
        id: 12,
        name: "Metal Wiper Blade",
        description: "It is having a 1.2MM thickness stainless stell coating. Aerdynamic design to increase resistance of wind lift",
        image: "/images/pro12.png",
      },
      {
        id: 13,
        name: "Crad Silver",
        description: "It is water and dust proof with UV protection and heat resistance.",
        image: "/images/pro13.png",
      },
      {
        id: 14,
        name: "Crad Legend",
        description: "It is water and dust proof with UV protection and heat resistance.",
        image: "/images/pro14.png",
      },
      {
        id: 15,
        name: "Electro Magnetic Horn",
        description: "It is water and dust proof with attractive design. Having twin tone horns and long life service.",
        image: "/images/pro15.png",
      },
      {
        id: 16,
        name: "Speaker Legend Series",
        description: "It is having 900W peak power and impedance upto 4ohm with a RMS as 80 Watts. It is also a two way high quality speaker.",
        image: "/images/pro16.png",
      },
      {
        id: 17,
        name: "Speaker Copper Series",
        description: "It is having 650W peak power and impedance upto 4ohm with a RMS as 80 Watts. It is also a two way high quality speaker.",
        image: "/images/pro17.png",
      },
      {
        id: 18,
        name: "High Quality Damping Mat",
        description: "It reduces vibration and rattles and also block unwanted ambient wind. It acts as a thermal insulation in dissipating heat and keeping your car cooler.",
        image: "/images/pro18.png",
      },
      {
        id: 19,
        name: "Active Base Tube 10inch",
        description: "It is corrosion and water resistance with great sound quality. Rubber coated surronded for minimised distortion.",
        image: "/images/pro19.png",
      },
      {
        id: 20,
        name: "MP3 Player",
        description: "A heavy duty black heat sink with bluetooth, AUX, 16 pin power socket, SD card, USB drive and FM radio",
        image: "/images/pro20.png",
      },
      {
        id: 21,
        name: "Integrated Andriod Player",
        description: "It is having a split screen with HD picture, it also have andrioidauto, apple carplay along with DSP.",
        image: "/images/pro21.png",
      },
      {
        id: 22,
        name: "3.4AMP Charger",
        description: "3 in 1 fast charging with 1000MM cable inside. It is fast, efficent and safe",
        image: "/images/pro22.png",
      },
      {
        id: 23,
        name: "AUX Cable",
        description: "High performance and easy installation with long service life.",
        image: "/images/pro23.png",
      },
      {
        id: 24,
        name: "LED Headlight 28000LM",
        description: "Corrosion and water proof with focused beam technology. Also have energy conservation along with premium quality built",
        image: "/images/pro24.png",
      },
      {
        id: 25,
        name: "Sub Woofer",
        description: "12inch injection subwoofer with 2000 peak power handling. Amazing base volume and sound quality",
        image: "/images/pro25.png",
      },
      {
        id: 26,
        name: "Speaker Treble Series",
        description: "It is having 880W peak power and impedance upto 4ohm with a RMS as 80 Watts. It is also a two way high quality speaker.",
        image: "/images/pro26.png",
      },
      {
        id: 27,
        name: "4inch Speaker",
        description: "It is having 280W peak power and impedance upto 4ohm with a RMS as 60 Watts. It is also a two way high quality speaker.",
        image: "/images/pro27.png",
      },
      {
        id: 28,
        name: "High Quality Damping Mat",
        description: "Reduces vibrations and rattles. Also block unwanted ambient wind, road noise and engine noise. Acts as a thermal insulation in dissipating heat and keep your car cooler.",
        image: "/images/pro28.png",
      },
      {
        id: 29,
        name: "Active Base Tube 8inch",
        description: "It is base tube with built-in amplifierIt has 300W/120W RMS power handling along with corrosion and water resistance.",
        image: "/images/pro29.png",
      },
      {
        id: 30,
        name: "Tyre Inflator",
        description: "Heavy duty material used for better inflation. It is dust and water resistance with long life service.",
        image: "/images/pro30.png",
      },
      {
        id: 31,
        name: "Integrated Android Player",
        description: "Dynamic large 9inch and 10inch capacitive HD display enjoy smooth and simple control for every journey, with a responsible diagonal 9inch and 10inch touchscreen display.",
        image: "/images/pro31.png",
      },
      {
        id: 32,
        name: "Integrated Android Player",
        description: "9inch and 10inch capacitive HD display with 32/64GB memory. Split screen HD picture with android auto and apple car play.",
        image: "/images/pro32.png",
      },
      {
        id: 33,
        name: "Car Mobile Charger",
        description: "Doc charger with 3.4 amp. Fast charging and very handy.",
        image: "/images/pro33.png",
      },
      {
        id: 34,
        name: "Screen Guard",
        description: "Available in 9inch and 10inch. Two way audio loudspeaker with plus one woofer cone.",
        image: "/images/pro34.png",
      },
      {
        id: 35,
        name: "LED Headlight  24000LM/Pair",
        description: "240 W pair with corrosion and water resistance. Focused beam technology and energy conservation with super long lifespan. More visibilty during night, premium quality and wet built.",
        image: "/images/pro35.png",
      },
      {
        id: 36,
        name: "LED Headlight  28000LM/Pair",
        description: "280 W pair with corrosion and water resistance. Focused beam technology and energy conservation with super long lifespan. More visibilty during night, premium quality and wet built.",
        image: "/images/pro36.png",
      },
      {
        id: 37,
        name: "OEM-Camera Venue.",
        description: "Full HD camera with high definition. Wide view angle and video rear view system. It is water proof and also have a night vision with low power consumption.",
        image: "/images/pro37.png",
      },
      {
        id: 38,
        name: "OEM-Camera Suzuki.",
        description: "Full HD camera with high definition. Wide view angle and video rear view system. It is water proof and also have a night vision with low power consumption.",
        image: "/images/pro38.png",
      },
      {
        id: 39,
        name: "OEM-Camera Suzuki.",
        description: "Full HD camera with high definition. Wide view angle and video rear view system. It is water proof and also have a night vision with low power consumption.",
        image: "/images/pro39.png",
      },

  ];
  