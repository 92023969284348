import './Product.css';
import { Link } from 'react-router-dom';
import React from 'react';


function ProductCard  ({ product }) {
    return (
      
      <div className="product-card">
        <Link to={`/product/${product.id}`}>
        <img src={product.image} alt={product.name} className="product-image" />
        <p className="product-name">{product.name}</p>
        <p className="product-description">{product.description}</p>
        <Link to={`/product/${product.id}`} className='links'>See Details ↗️</Link>
        </Link>
      </div>
      
    );
}

export default ProductCard;