import React, { useState, useRef } from "react";
import "./Product.css";
import { datas } from "./data";
import ProductCard from "./Product-card";
import NavBar from "./Navbar";
import { Link } from "react-router-dom";
function Product() {
  const [currentPage, setCurrentPage] = useState(0);
  const productsPerPage = 12;
  const productsHeadingRef = useRef(null);

  const handleNext = (e) => {
    e.preventDefault();

    if ((currentPage + 1) * productsPerPage < datas.length) {
      setCurrentPage(currentPage + 1);

      if (productsHeadingRef.current) {
        productsHeadingRef.current.scrollIntoView({ behavior: "smooth" });
      }
    }
  };

  const handlePrevious = () => {
    if (currentPage > 0) {
      setCurrentPage(currentPage - 1);

      if (productsHeadingRef.current) {
        productsHeadingRef.current.scrollIntoView({ behavior: "smooth" });
      }
    }
  };

  const startIndex = currentPage * productsPerPage;
  const selectedProducts = datas.slice(
    startIndex,
    startIndex + productsPerPage
  );

  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className="product">
      <NavBar></NavBar>

      <div className="product-main">
        <div className="product-main1">
          <p className="f1">Spring Sale </p>
          <p className="f2">Up to-40% off</p>
          <p className="f3">
            Enjoy a massive 40% off on all our products for a limited time only.
            Don't miss out on these unbeatable deals—shop now and save big!
          </p>
          <button>See Offers ↗</button>
        </div>
        <div className="product-main2">
          <div className="product-img">
            <img src="/images/img1.png"></img>
          </div>
          <div className="product-img">
            <img src="/images/img2.png"></img>
          </div>
          <div className="product-img1">
            <img src="/images/img3.png"></img>
          </div>
          <div className="product-img1">
            <img src="/images/img4.png"></img>
          </div>
        </div>
      </div>

      <div className="pro-heading" ref={productsHeadingRef}>
        <p>Products</p>
      </div>

      <div className="product-list">
        {selectedProducts.map((data) => (
          <ProductCard key={data.id} product={data}></ProductCard>
        ))}
      </div>
      <hr className="hrline" />
      <div className="pagination-buttons">
        <button onClick={handlePrevious} disabled={currentPage === 0}>
          &larr; Previous
        </button>
        <button
          onClick={handleNext}
          disabled={(currentPage + 1) * productsPerPage >= datas.length}
        >
          Next &rarr;
        </button>
      </div>

      <footer>
        <p className="footer-heading">Subscribe to Our Newsletter</p>
        <p className="footer-desc">
          Subscribe to our newsletter today to get all the latest updates!
        </p>
        <Link to="/contact">
          <button className="footer-but">
            <img src="/images/RocketLaunch.png"></img>
            Get Started
          </button>
        </Link>
        <div className="footer-img">
          <img src="/images/footer-bg2.png"></img>
        </div>
      </footer>
    </div>
  );
}

export default Product;
