import './App.css';
import Home from './Home';
import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Product from './Product';
import ProductDetail from './ProductDetails';
import About from './About';
import ScrollToTop from './ScrollToTop';
import Contact from './Contact';

function App() {
  return (
    <Router>
      <ScrollToTop />
      <Routes>
        <Route path="/product" element={<Product/>} />
        <Route path="/" element={<Home/>}/>
        <Route path="/product/:id" element={<ProductDetail/>} />
        <Route path="/about" element={<About/>}/>
        <Route path="/contact" element={<Contact/>}/>
      </Routes>
    </Router>
  );
}

export default App;
